<template>
    <div class="user-gifts pd-10 h-calc-60">
        <div class="search-content">
            <el-form inline>
                <el-form-item label="手机号">
                    <el-input v-model="phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="是否已拿到商品">
                    <el-select v-model="status" placeholder="请选择">
                        <el-option v-for="(item, index) in statusList" :key="index" :value="item.value" :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="礼品ID">
                    <el-input v-model.number="id" style="width: 100px;"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="datas">
                        <span style="font-size: 16px;">
                            <el-icon><Search /></el-icon>
                        </span>
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-data">
            <el-table :data="tableData" height="100%">
                <el-table-column align="center" label="记录ID" prop="account_gift_id" width="80"></el-table-column>
                <el-table-column align="center" label="昵称" prop="nickname" width="130"></el-table-column>
                <el-table-column align="center" label="头像">
                    <template #default="scope">
                        <image-c :src="scope.row.avatar_url" :alt="scope.row.nickname"></image-c>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="礼品名称" width="200" prop="gift_name"></el-table-column>
                <el-table-column align="center" label="礼品主图">
                    <template #default="scope">
                        <image-c :src="scope.row.gift_img" :alt="scope.row.gift_name"/>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="兑换码" prop="code"></el-table-column>
                <el-table-column align="center" label="是否已经领取礼品" width="200">
                  <template #default="scope">
                    <el-switch v-model="scope.row.status" inline-prompt
                               inactive-text="否"
                               active-text="是"
                               disabled
                               active-color="#13ce66"
                    ></el-switch>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="领取时间">
                  <template #default="scope">
                      {{scope.row.take_time === '' ? '未领取' : scope.row.take_time}}
                  </template>
                </el-table-column>
                <el-table-column align="center" label="创建时间" prop="create_time"></el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button type="primary" @click="takeGiftConfirm(scope.row.account_gift_id)">领取</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <page-component :page="page" :limit="limit" :total="total" @change="changePage"></page-component>
    </div>
</template>

<script setup>
import PageComponent from "@/components/common/PageComponent"
import {onMounted, ref} from "vue";
import {Search} from "@element-plus/icons-vue";
import {LogsData, takeGift} from "@/utils/apis";
import {ElMessage, ElMessageBox} from "element-plus";
import ImageC from "@/components/common/ImageC"

const page = ref(1)
const limit = ref(10)
const total = ref(0)
const changePage = (val) => {
  page.value = val
}
const phone = ref('')
const statusList = ref([
  {name: '全部', value: 2},
  {name: '是', value: 1},
  {name: '否', value: 0}
])
const status = ref(2)
const id = ref('')
const tableData = ref([])
const datas = async () => {
    try {
      let param = {
          paging: 1,
          page: page.value,
          pageSize: limit.value,
      }
      if (phone.value.trim()) {
          param.mobile = phone.value.trim()
      }
      if (status.value !== 2) {
          param.status = status.value
      }
      if (id.value) {
          param.gift_id = id.value
      }
      const {data, msg} = await LogsData(param)
      tableData.value = data.list.map(item => {
          item.status = Number(item.status) === 1
          return item
      })
      total.value = data.total

    } catch (e) {
        ElMessage.warning(e.message)
    }
}
const takeGiftConfirm = async (id) => {
    ElMessageBox.confirm(
        '确认核对信息一致',
        '确认领取礼品',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }
    ).then(() => {
        takeGifted(id)
    }).catch(err => {
        console.log(err)
    })
}
const takeGifted = async (id) => {
    try {
        const {data, msg} = await takeGift(id)
        ElMessage({
          type: 'success',
          message: msg,
          duration: 800,
          onClose: () => {
            datas()
          }
        })
    } catch (e) {
        ElMessage.warning(e.message)
    }
}
onMounted(() => {
  datas()
})
</script>

<style scoped lang="scss">
.table-data {
    height: calc(100% - 130px);
    padding: 20px 0;
}

</style>