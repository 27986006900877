<template>
    <div class="page-content" :style="style">
      <el-pagination background :current-page="currentPage" :page-size="pageSize" :total="total" @current-change="pageChange"/>
    </div>

</template>

<script setup>
import {defineProps, defineEmits, ref, toRef} from "vue";
const emits = defineEmits(['change'])
const props = defineProps({
  total: Number,
  limit: {
      type: Number,
      default: 10
  },
  page: {
    type: Number,
    default: 1
  },
  style: {
    type: String,
    default: ''
  }
})
const currentPage = toRef(props, 'page')
const pageSize = toRef(props, 'limit')
const total = toRef(props, 'total')
const pageChange = (val) => {
    emits('change', val)
}


</script>

<style scoped lang="scss">
.page-content {
    height: 40px;
    &, & * {
       box-sizing: border-box;
    }
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>