<template>
    <div class="image-body">
      <div class="image-content">
        <img :src="src" :alt="alt">
      </div>
    </div>

</template>

<script setup>
import {defineProps, toRef} from "vue";
const props = defineProps({
    src: String,
    alt: {
        type: String,
        default: ''
    }
})
const src = toRef(props, 'src')
const alt = toRef(props, 'alt')

</script>

<style scoped lang="scss">
.image-body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-content {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
    }
}

</style>